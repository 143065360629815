<template>
  <base-section id="404" space="0">
    <section id="header-home" ref="headerHome" class="white--text">
      <h1 class="text-h2 text-sm-h1 text-uppercase">
        {{ title }}
      </h1>
      <h3 class="text-h4 text-sm-h3">
        {{ subtitle }}
      </h3>
      <base-btn color="success" :to="{ name: 'Menu' }">
        {{ $t("btnHome") }}
      </base-btn>
    </section>
    <v-container class="fill-height px-4 pt-12 pb-6 d-block">
      <p class="text-home text-center">
        {{ description }}
      </p>
    </v-container>
  </base-section>
</template>

<script>
import { useStorageUtils, setCSSVar } from "@/utils";
import { getTextSettingValue } from "@/helpers";
import { settingsDigitalLetterStoreGetters } from "@/store/modules/settingsDigitalLetter/constNames";

export default {
  name: "WelcomeSection",

  provide: {
    heading: { align: "center" },
  },

  mounted() {
    this.bgImg && setCSSVar("--bgImage", `url('${this.bgImg}')`);
  },

  computed: {
    texts() {
      return this.$store.getters[settingsDigitalLetterStoreGetters.GET_TEXTS];
    },
    bgImg() {
      return this.$store.getters[settingsDigitalLetterStoreGetters.GET_IMAGES]
        ?.header;
    },
    languageKey() {
      const { getItem } = useStorageUtils();
      return getItem("lang") ?? "en";
    },
    title() {
      const value = getTextSettingValue(this.texts.title[this.languageKey]);
      return getTextSettingValue(value, "titleHome");
    },
    subtitle() {
      const value = this.texts.subtitle[this.languageKey];
      return getTextSettingValue(value, "subtitleHome");
    },
    description() {
      const value = this.texts.description[this.languageKey];
      return getTextSettingValue(value, "textHome");
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    bgImg(newValue, _) {
      newValue && setCSSVar("--bgImage", `url('${this.bgImg}')`);
    },
  },
};
</script>
<style lang="sass">
:root
  --bgImage: url('../../../assets/img/background-about-us2.webp')

#header-home
  background: var(--bgImage) center center / cover no-repeat
  min-height: 320px
  height: 50vh
  max-height: 500px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 15px
  text-align: center

.text-home
  text-align: center
  font-size: 1.2rem
  white-space: pre-line
</style>
